import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root/Root.js';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Root />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('330d6c99-8553-4b69-aa1a-1283d994d905')
);
