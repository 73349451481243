import React from 'react';
import {
	Switch,
	Route
} from 'react-router-dom';
import { withRouter } from 'react-router';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import ContentPage from '../ContentPage';
import LiteDAOHeader from '../LiteDAOHeader';
import LiteDAONavigation from '../LiteDAONavigation';

const styles = function(theme) {
	return({
		root: {
			display: 'flex'
		},
		toolbar: theme.mixins.toolbar,
		content: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.default,
			padding: theme.spacing(3),
		}
	});
};

function ErrorPage() {
	return(<div>This page does not exist.</div>);
}

class Root extends React.Component {
	static Pages = [
		...(Object.keys(ContentPage.Pages).sort(function(path_a, path_b) {
			const info_a = ContentPage.Pages[path_a];
			const info_b = ContentPage.Pages[path_b];

			if (info_a.order < info_b.order) {
				return(-1);
			} else if (info_a.order > info_b.order) {
				return(1);
			} else {
				return(0);
			}

		}).map(function(path) {
			const info = ContentPage.Pages[path];

			return({
				type: 'link',
				text: info.text,
				icon: info.icon,
				link: path
			});
		})),
		{
			type: 'divider'
		}
	]

	render() {
		const classes = this.props.classes;
		return(
			<div className={classes.root}>
				<CssBaseline />
				<LiteDAOHeader/>
				<LiteDAONavigation items={Root.Pages} selected_pathname={window.location.pathname}/>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					<Switch>
						{Object.keys(ContentPage.Pages).map(function(path) {
							return(<Route exact path={path} component={ContentPage} key={`page_route_${path}`} />);
						})}
						<Route path="/" component={ErrorPage} />
					</Switch>
				</main>
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(Root));
