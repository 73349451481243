import React from 'react';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as Icons from '@material-ui/icons/';
import Label from '@material-ui/icons/Label';

import { withStyles } from '@material-ui/core/styles';

const styles = function(theme) {
	const drawerWidth = LiteDAONavigation.drawerWidth;

	return({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		}
	});
};

function DynamicIcon(props) {
	const pass_props = {
		...props
	};
	delete pass_props['name'];

	return(React.createElement(Icons[props.name], pass_props));
}

class LiteDAONavigation extends React.Component{
	static drawerWidth = '15rem';

	render_item(item, key) {
		switch (item.type) {
			case 'link':
				let icon = <DynamicIcon name={item.icon} color='secondary' />;
				if (item.link === this.props.selected_pathname) {
					icon = <Label color='primary' />;
				}
				return(
					<ListItem button key={key} component={Link} to={{pathname: item.link}}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={item.text} />
					</ListItem>
				);
			case 'divider':
				return(<Divider key={key} />);
			default:
				console.error('Unknown type:', item);
		}
	}

	render() {
		const classes = this.props.classes;

		return(
			<Drawer
				className={classes.drawer}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper
				}}
				anchor="left"
			>
				<Divider />
				<List>
					{this.props.items.map((item, index) => {
						const key = `nav_item_${index}`;
						return(this.render_item(item, key));
					})}
				</List>
			</Drawer>
		);
	}
}

export default withStyles(styles)(LiteDAONavigation);
