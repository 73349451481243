import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
	return(<div>Home page goes here</div>);
}

function About() {
	return(
		<div>
			LiteDAO is all about <Link to={{pathname: '/pages/compatability'}}>compatability</Link> !
		</div>
	);
}

function Compatability() {
	return(
		<div>
			LiteDAO recognizes the work of so many pioneering projects.
		</div>
	);
}

function Placeholder() {
	return(<div>This page is under construction.  Check back soon !</div>);
}

class ContentPage extends React.Component{
	static Pages = {
		'/': {
			order: 0,
			icon: 'Home',
			text: 'Home',
			content: <Home/>
		},
		'/pages/about': {
			order: 1,
			icon: 'Info',
			text: 'About',
			content: <About/>
		},
		'/pages/compatability': {
			order: 2,
			icon: 'List',
			text: 'Compatability',
			content: <Compatability/>
		},
		'/pages/whoami': {
			order: 2,
			icon: 'PersonPin',
			text: 'Who is LiteDAO',
			content: <Placeholder/>
		}
	};

	render() {
		const pathname = this.props.match.path;
		return(ContentPage.Pages[pathname].content);
	}
}

export default ContentPage;
